import React from 'react';
import { Link, graphql } from "gatsby"

const BlogPost = ({node}) => {
  return(
  <li>
    <Link to={node.slug}>
      <h2>{node.title}</h2>
    </Link>

  </li>
  )
}

const BlogList = ({data}) => (
  <ul>
    {data.allContentfulArticle.edges.map((edge) => <BlogPost key={edge.node.id} node={edge.node}/>)}
  </ul>
 
)

export default BlogList;

export const pageQuery = graphql`
  query pageQuery {
    allContentfulArticle {
      edges {
        node {
          id
          title
          slug
        }        
      }
    }
  }
`